// Fix le calcul des vh sur iOS et Chrome Android (css var --vh injectée en JS)
class calculateVheight {
    constructor() {
        this.prevClientHeight = null;
        this.calculate();

        // resize seulement en desktop
        if(window.innerWidth >= 576) window.addEventListener('resize', this.calculate.bind(this));
    }
    calculate() {
        // nouveau calcul seulement quand la hauteur change
        if (window.innerHeight !== this.prevClientHeight) {
            document.documentElement.style.setProperty('--vh', (window.innerHeight * 0.01) + 'px');
            this.prevClientHeight = window.innerHeight;
        }
    }
}
const notificationsWrapper = document.querySelector('.js-notifications');
const notificationsCount = document.querySelectorAll('.js-notification-count');
const notificationsDataCount = document.querySelectorAll('[data-notifications]');

document.querySelectorAll('[data-notification-remove]' ).forEach((elem) => {
    elem.addEventListener('click', (e) => {
        e.preventDefault();
        elem.closest('.js-notifications-card').remove();

        const data = {
            action: 'notifications_remove',
            nonce:  elem.dataset.nonce,
            postid: elem.dataset.notificationRemove,
        };
        fetch(ajaxurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: new URLSearchParams(data),
        })
            .then(response => response.json())
            .then(body => {
                const countNotifications = notificationsWrapper.querySelectorAll('.js-notifications-card').length;
                notificationsCount.forEach((notifCount) => {
                   notifCount.innerHTML = countNotifications.toString();
                });
                notificationsDataCount.forEach((notifCount) => {
                   notifCount.dataset.notifications = countNotifications.toString();
                });

            });
    });
});
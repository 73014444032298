// Languages selector
$('.js-lang-dropdown-desktop > a').on("click", function (e) {
    e.preventDefault();
    $(this).next().toggleClass("is-open");
});

if (isIE()) {
    document.getElementsByTagName('body')[0].className += ' ie';
}

if (isNoHover()) {
    document.getElementsByTagName('body')[0].className += ' no-hover';
}



window.onload = () => {
    //il faut attendre que le contenu soit painted pour pouvoir récupérer la taille de l'élément donc on attend le onload
    th_accordeon.init();
};
th_overlay.init();
th_expand.parse();
th_ancres.init();

// Fix le calcul des vh sur iOS et Chrome Android (css var --vh injectée en JS)
new calculateVheight();

// affichage des overlays possible que a partie de 2s
setTimeout(function () {
    th_overlay.init();
    $('body').addClass('overlay-load');
}, 1000);

// Lazyload
var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
});

// Lancement du script Librairie Zoombox
$('.zoombox').zoombox();

/* Detect the scroll of the page down */
document.addEventListener('scroll', function () {
    var st = $(this).scrollTop();

    if (st > 100) {
        $("body").addClass("is-scrolled");
    } else {
        $("body").removeClass("is-scrolled");
    }
});


/* Detect the scroll of the page up */
var lastscrolltop = 0;
var lastIsDirTop = 0;
document.addEventListener('scroll', function () {
    var st = $(document).scrollTop();
    if (st < lastscrolltop && lastIsDirTop == 0) {
        lastIsDirTop = 1;
        $(document.body).addClass('scrolldir-top', true);
    }
    if (st > lastscrolltop && lastIsDirTop == 1) {
        lastIsDirTop = 0;
        $(document.body).removeClass('scrolldir-top', true);
    }
    lastscrolltop = st;
});

$('a figcaption').on('touchstart click', function (e) {
    e.stopPropagation();
    e.preventDefault();
});

$('a.download-data-multiple').each(function () {
    $(this).click(function (e) {
        e.preventDefault();
        $('.data-multiple a', $(this).parents(".card")).each(function () {
            this.click();
        });
    });
});

function parse_vcard_btn() {
    $('.js-vcard').click(function (e) {
        e.stopPropagation();
        e.preventDefault();


        var data = {
            'action': 'download_vcard',
            'user_id': $(this).data('userid')
        };

        $.ajax({
            url: localizeParams.ajaxurl, // this will point to admin-ajax.php
            type: 'POST',
            data: data,
            success: function (response) {
                console.log(response);
                const vcard = JSON.parse(response);
                const blob = new Blob([vcard.data], {type: "application/octet-stream"});
                const contentDisposition = vcard.headers['Content-Disposition'];
                const fileName = contentDisposition.substring(contentDisposition.indexOf("=") + 1);

                let tempLink = document.createElement('a');
                tempLink.href = window.URL.createObjectURL(blob);
                tempLink.download = fileName;

                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);

                setTimeout(() => {
                    window.URL.revokeObjectURL(blob);
                }, 100);

            }
        });
    });
}

parse_vcard_btn();

document.querySelectorAll('.js-toggle-password').forEach((el) => {
    const toggleButton = el.querySelector('button');
    const input = el.querySelector('input');
    var inputType = 'password';


    toggleButton.addEventListener('click', (e) => {
        e.preventDefault();
        const span = toggleButton.querySelector('span');
        span.classList.toggle('dashicons-visibility');
        span.classList.toggle('dashicons-hidden');
        inputType = (inputType == 'password') ? 'text' : 'password';
        input.setAttribute('type', inputType);
    })
});





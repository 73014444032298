/**
 * Gestion du formulaire de saisie des informations pour chacun des guests
 */
class EventsRegistrationFormGuest {

    selectors = {
        btnRemove: '.js-registration-btn-remove-guest',
        guestForm: '.js-registration-form-guest',
        containerGuest: '.participant',
        containerCheckboxes: '.js-registration-form-container-chekboxesfirstname',
        containerVisits: '.js-registration-choose-visit',
        containerVisit: '.js-registrastion-visit-guest',
        containerVisitsTechnic: '.js-registration-choose-visit-technic',
        containerVisitTechnic: '.js-registrastion-visit-technic',
        containerEvent: '.js-registration-event',
        attributeGuestID: 'data-js-guestid',
        attributeEventID: 'data-js-eventid',
        guestNumber: '.js-registration-guest-number',
    };

    fields = {};
    form = null;
    btnDelete = null;
    btnAdd = null;
    guest = null;
    label = null;
    inputs = {};
    hasMeal = false;
    price = 0;
    proxy = null;

    constructor(id_guest, price, hasMeal, proxy, data = null) {
        this.guest = new EventsRegistrationGuest(id_guest);
        this.hasMeal = hasMeal
        this.price = price
        this.guest.id_guest = id_guest;
        this.proxy = proxy;
        this.fields = registrationFields; // variable set by wordpress

        this.add(data);
    }

    add(data) {
        this.form = document.createElement('div');
        this.form.classList.add('participant');
        if(data) {
            this.form.classList.add('participant--card');
            this.proxy.isAdd = true;
        }
        this.form.setAttribute(this.selectors.attributeGuestID, this.guest.id_guest);

        const card = document.createElement('div');
        card.classList.add('participant__card');

        this.label = document.createElement('div');
        this.label.classList.add('participant__label');
        this.label.innerHTML = '<strong>' + data?.fullname + '</strong> ('+ data?.function +')' + (this.price ? ' (' + this.price + '€)' : '');

        card.appendChild(this.label);

        this.form.appendChild(card);

        this.form.appendChild(this.createForm(this.guest.id_guest, data));

        this.btnAdd = document.createElement('button');
        this.btnAdd.classList.add('participant__add');
        this.btnAdd.innerHTML = registrationWording.add_guest;

        this.btnDelete = document.createElement('button');
        this.btnDelete.classList.add('participant__delete');
        this.btnDelete.innerHTML = '<i class="icon-trash"></i>';

        this.form.appendChild(this.btnAdd);
        this.form.appendChild(this.btnDelete);

        this.initListeners(this.guest.id_guest);
    }

    initListeners(id_guest) {
        this.btnAdd.addEventListener("click", (e) => {
            e.preventDefault();

            if(this.form.closest('form')?.checkValidity()) {
                this.toggleFormToCard();
                this.proxy.isAdd = true;
            }
        });

        // gestion du bouton de suppression du formulaire du participant
        this.btnDelete.addEventListener("click", (e) => {
            e.preventDefault();
            this.form.remove();
            this.proxy.toTrash = true;
        });
    }

    createElementFromHtml(html) {
        const template = document.createElement('template');
        template.innerHTML = html.trim();
        return template.content.firstChild;
    }

    createFormFieldText(id_guest, name, value = '') {
        const label = this.fields[name].label;
        const placeholder = this.fields[name].placeholder;
        const id = 'guest_' + id_guest + '_' + name;
        const inputName = 'guest[' + id_guest + '][' + name + ']';

        const formField = this.createElementFromHtml('  <div class="form-field">' +
            '       <label for="' + id + '" class="frm_primary_label">' + label  + '</label>' +
            '       <input type="text" id="' + id + '" name="' + inputName + '" placeholder="' + placeholder + '" value="' + value + '" data-js-type="' + name + '" required>' +
            '       <div class="form-field__error">Le champs est requis</div>' +
            '   </div>');

        const input = formField.querySelector('#' + id);
        this.inputs[name] = input;
        input.addEventListener('invalid', (e) => {
            formField.classList.add('form-field--error');
        });
        input.addEventListener('change', () => {
            formField.classList.remove('form-field--error');
        });

        return formField;
    }

    createFormFieldToggle(id_guest, name, check = false) {
        const label = this.fields[name].label;
        const id = 'guest_' + id_guest + '_' + name;
        const inputName = 'guest[' + id_guest + '][' + name + ']';

        return this.createElementFromHtml('<div class="form-field">' +
            label + ' : ' +
            '<label class="switch">' +
                '<input type="checkbox" name="' + inputName + '" id="' + id + '"' + (check ? ' checked' : '') + '>' +
                '<span class="switch__toggle"></span>' +
            '</label> ' +
        '</div>');
    }

    createForm(id_guest, data) {
        const form = document.createElement('div');

        const formCreate = document.createElement('div');
        formCreate.classList.add('form-fields');
        formCreate.classList.add('form-fields__create');
        formCreate.classList.add(this.selectors.guestForm.replace('.', ''));
        formCreate.setAttribute(this.selectors.attributeGuestID, id_guest);
        formCreate.appendChild(this.createFormFieldText(id_guest, 'fullname', data?.fullname ?? ''));
        formCreate.appendChild(this.createFormFieldText(id_guest, 'function', data?.function ?? ''));

        form.appendChild(formCreate);

        if(this.hasMeal) {
            const formUpdate = document.createElement('div');
            formUpdate.classList.add('form-fields');
            formUpdate.classList.add('form-fields__update');
                formUpdate.appendChild(this.createFormFieldToggle(id_guest, 'meal_participate', data?.meal_participate ?? false))
            form.appendChild(formUpdate);
        }

        return form;
    }

    toggleFormToCard() {
        this.form.classList.add('participant--card');
        const fullname = this.inputs['fullname'].value;
        const func = this.inputs['function'].value;
        this.label.innerHTML = '<strong>' + fullname + '</strong> ('+ func +')' + (this.price ? ' (' + this.price + '€)' : '');
    }
}
function parseCardAnnuaireClickOverlay() {
    $('.js-card-annuaire-open-overlay:not(.parsed)').on('click', function (e) {
        e.preventDefault();

        const elem = $(this);

        const ajax_url = elem.attr('href') + '?overlay';
        const overlay_id = 'overlay-user';

        $.get(ajax_url, function (responseHTML) {
            $('#' + overlay_id + ' .loader').addClass('hide');
            $('#' + overlay_id + ' .js-overlay-content').html(responseHTML);
            parse_vcard_btn();
            th_accordeon.init();
        });

        th_overlay.open(overlay_id, true, true, false);

    }).addClass('parsed');
}

parseCardAnnuaireClickOverlay();

function parseCardClickOverlay() {
    $('.js-card-open-overlay.card:not(.parsed)').on('click', function (e) {
        e.preventDefault();

        const elem = $(this);
        const ajax_url = elem.attr('href') + '?overlay';
        const overlay_id = 'overlay-' + elem.attr('data-posttype');

        $.get(ajax_url, function (responseHTML) {
            $('#' + overlay_id + ' .loader').addClass('hide');
            $('#' + overlay_id + ' .js-overlay-content').html(responseHTML);
            if(overlay_id == 'overlay-district'
                || overlay_id == 'overlay-association'
                || overlay_id == 'overlay-commission') {
                parseCardAnnuaireClickOverlay();
            }
        });

        th_overlay.open(overlay_id, true, true, false);

    }).addClass('parsed');
}

parseCardClickOverlay();

th_overlay.addCallbackClose(function (overlayId) {
    $('#' + overlayId + ' .js-overlay-content').html('');
    $('#' + overlayId + ' .loader').removeClass('hide');
});

(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-images');
    sliders.forEach(slider => {
        const splide = new Splide(slider, {
            pagination: false
        }).mount();
    });

})();
(function () {

    // Quand une checkbox / radio est coché, ajoute une classe sur le container parent
    $('.bloc-form .frm_checkbox input[type="checkbox"]').on('change', function () {
        if($(this).is(':checked')) {
            $(this).closest('.frm_checkbox').addClass('is-checked');
        } else {
            $(this).closest('.frm_checkbox').removeClass('is-checked');
        }
    });


    $('.bloc-form .frm_radio input[type="radio"]').on('change', function () {
        var $container = $(this).closest('.frm_opt_container');

        $($container).find('input[type="radio"]').each(function() {
            if(this.checked) {
                $(this).closest('.frm_radio').addClass('is-checked');
            } else {
                $(this).closest('.frm_radio').removeClass('is-checked');
            }
        });
    });


    // Quand un switch est coché, ajoute une classe sur le container parent
    $('.bloc-form .frm_switch_block input[type="checkbox"]').on('change', function () {
        if($(this).is(':checked')) {
            $(this).closest('.frm_switch_block').addClass('is-checked');
        } else {
            $(this).closest('.frm_switch_block').removeClass('is-checked');
        }
    })

})();

class EventsRegistrationGuest {
    id = null;
    fullname = null;
    function = null;
    meal = false;

    constructor(id) {
        this.id = id;
    }

    getDisplayName() {
        return this.fullname;
    }
}
class EventsRegistration {
    cobatyste = null;
    guests = [];
    tempFormGuest = null;
    formGuest = null;
    formMonetico = null;
    summary = null;
    Events = null;
    selectors = {
        page: '.overlay-events-registration',
        form: '.js-events-registration-form',
        formRIB: '.js-registration-rib-form',
        errorForm: '.frm_error_style',
        total: '.js-registration-total-price',
        inputTotal: 'input[name="total"]',
        submitBtn: '.js-registration-submit',
        attributePaymentMethod: 'data-js-paymentmethod',
        inputPaymentMethodName: 'payment_mode',
        cobatysteForm: '.js-registration-form-cobatyste',
        cobatysteCheckboxFirstname: '.js-registration-cobatyste-chekboxesfirstname',
        companyNameModale: 'input[name="modale_societe"]',
        companyNameForm: 'input[name="raison_sociale"]',
        btnAddGuest: '.js-events-registration-btn-add-guest',
        containerParticipants: '.js-registration-container-guest',
    };

    total = 0;
    containerParticipants = null;
    cobatystePrice = 0;
    guestPrice = 0;
    maxGuests = 0;
    hasMeal = false;
    remainingSeat = 0;
    btnAddGuest = null;
    form = null;

    constructor() {

        if (!document.querySelector(this.selectors.page)) {
            return;
        }

        this.eventsRegistrationPostID = null;
        this.btnAddGuest = document.querySelector(this.selectors.form + ' ' + this.selectors.btnAddGuest);
        this.containerParticipants = document.querySelector(this.selectors.containerParticipants);
        this.form = document.querySelector(this.selectors.form);
        this.cobatystePrice = Number(this.form.dataset.cobatystePrice) || 0;
        this.guestPrice = Number(this.form.dataset.guestPrice) || 0;
        this.maxGuests = Number(this.form.dataset.maxGuests) || 0;
        this.remainingSeat = Number(this.form.dataset.remainingSeat) || 0;
        this.hasMeal = !!this.form.dataset.hasMeal;

        th_overlay.addCallbackOpen((overlayId) => {
            console.log(overlayId);
            if(overlayId === 'overlay-events-validation') {

            }
        });

        document.querySelector('.js-event-registration-update')?.addEventListener('click', (ev) => {
            ev.preventDefault();
            th_overlay.open('overlay-events-registration');
        });
        document.querySelector('.js-event-registration-paiement')?.addEventListener('click', (ev) => {
            ev.preventDefault();
            th_overlay.open('overlay-events-paiement');
        });

        const that = this;

        // Update Guests when Guests array is modified
        const proxyGuestsManager = {
            get(obj, prop) {
                if(prop === 'length') {
                    return obj.length;
                }

                return Reflect.get(...arguments);
            },
            set(obj, prop, value) {
                if(prop !== 'length') {
                    obj[prop] = value;
                    that.updateForm();
                    return true;
                }

                return Reflect.set(...arguments);
            },
            deleteProperty(target, prop) {
                if (prop in target) {
                    target.splice(prop, 1);
                    that.updateForm();
                    return true;
                }
            }
        };

        this.guests = new Proxy(this.guests, proxyGuestsManager);

        this.addListenersOnce();
        this.loadGuests();
    }

    addListenersOnce() {
        // bouton d'ajout d'un accompagnant
        this.form.addEventListener('submit', (ev) => {
           ev.preventDefault();
           this.save();
        });
        this.btnAddGuest.addEventListener("click", (e) => {
            e.preventDefault();
            const id_guest = this.guests.length; // Start at 0
            this.hideAddButton();

            if (id_guest < this.maxGuests) {
                this.addFormGuest(id_guest);
            }
        });
    }

    addFormGuest(id_guest, data = null) {
        const that = this;
        const handler = {
            set(obj, prop, value) {
                if(prop === 'isAdd' && value) {
                    that.addGuest(that.tempFormGuest);
                    this.tempFormGuest = null;
                } else if(prop === 'toTrash' && value) {
                    that.trashGuest(obj.guestId);
                }

                return Reflect.set(...arguments);
            }
        };

        const proxy = new Proxy({
            guestId: id_guest,
            isAdd: false,
            toTrash: false,
        }, handler);

        this.tempFormGuest = new EventsRegistrationFormGuest(id_guest, this.guestPrice, this.hasMeal, proxy, data);
        this.containerParticipants.append(this.tempFormGuest.form);
    }

    addGuest(formGuest) {
        this.guests.push(formGuest);

        if(this.guests.length < this.maxGuests) {
            this.showAddButton();
        }
    }

    trashGuest(guestId) {
        delete this.guests[guestId];
        this.showAddButton();
    }

    updateForm() {
        this.updateTotal();
    }

    loadCobatyste() {
        // init du TomSelect sur le cobatyste
        const ts = new TomSelect('#user_diet', {
            plugins: ['remove_button', 'no_active_items']
        });

        // update du formulaire du cobatyste
        const selector_textarea = this.selectors.cobatysteForm + ' textarea';
        const selector_select = this.selectors.cobatysteForm + ' select';
        let inputs = document.querySelectorAll(selector_textarea + ',' + selector_select);
        inputs.forEach((input) => {
            input.addEventListener('change', (event) => {
                this.saveRegistration();
            })
        });

        this.cobatyste = new RegistrationGuest();
        this.cobatyste.lastname = registrationCobatyste.lastname;
        this.cobatyste.firstname = registrationCobatyste.firstname;
        this.cobatyste.diet = registrationCobatyste.diet;
        this.cobatyste.details = registrationCobatyste.details;
        this.cobatyste.schedule = registrationCobatyste.schedule;

        // update du contenu des visites techniques
        const containersVisits = document.querySelectorAll(this.formGuest.selectors.containerVisitsTechnic);
        containersVisits.forEach((container) => {
            const id_event = container.getAttribute(this.formGuest.selectors.attributeEventID);
            if (typeof this.cobatyste['schedule'] != "undefined") {
                const choosen_visit = this.cobatyste['schedule'][id_event];
                if (choosen_visit) {
                    this.Visit.updateSummaryVisitContent(
                        'overlay-event-' + id_event,
                        id_event,
                        choosen_visit
                    );
                }
            }
        });

        // listener sur les checkbox de participation
        const checkboxes = document.querySelectorAll(this.selectors.cobatysteCheckboxFirstname);
        checkboxes.forEach((node) => {
            node.addEventListener("change", (e) => {
                this.saveRegistration();
            });
        });

        this.updateTotal();
    }

    loadGuests() {
        const guests = registrationCurrentGuests;

        if (guests && guests.length > 0) {
            var count = 0;
            guests.forEach((guest, guest_id) => {
                if(count < this.maxGuests) {
                    this.addFormGuest(guest_id, guest);
                }
            });

            if (guests.length >= this.maxGuests) {
                this.hideAddButton();
            }

            this.updateTotal();
        }
    }

    updateTotal() {
        const total = this.cobatystePrice + this.getGuestsPrice();
        document.querySelector(this.selectors.total).textContent = total;
        document.querySelector(this.selectors.inputTotal).value = total;
    }

    getGuestsPrice() {
        return this.guests.length * this.guestPrice;
    }

    saveRegistration() {
        let form = document.querySelector(this.selectors.form);

        fetch(ajaxurl, {
            method: 'POST',
            body: new FormData(form),
        })
            .then(response => response.json())
            .then(response => {
                // En cas d'erreur
                if (!response.success) {
                    alert(response.data);
                    return;
                }

                return true;
            });
    }

    showAddButton() {
        this.btnAddGuest.classList.remove('hide');
    }

    hideAddButton() {
        this.btnAddGuest.classList.add('hide');
    }

    save() {
        let form = document.querySelector(this.selectors.form);

        fetch(ajaxurl, {
            method: 'POST',
            body: new FormData(form),
        })
            .then(response => response.json())
            .then(response => {
                // En cas d'erreur
                if(response.success) {
                    th_overlay.open('overlay-events-validation');
                }
                if (!response.success) {
                    alert(response.data);
                    return;
                }
            });
    }
}